import React from "react";
import "./Team.scss";
import TeamCards from "../../components/TeamCards/TeamCards";
import TeamDesc from "../../components/TeamDesc/TeamDesc";
function Team() {
  return (
    <div className="team-section--wrapper">
      <TeamCards />
      <TeamDesc />
    </div>
  );
}

export default Team;
