import React, { Component } from "react";
import "./Follow.scss";

class Follow extends Component {
  render() {
    return (
      <div className="footer-left d-flex justify-content-between align-items-center">
        <h3>Follow us</h3>
        <a
          href="https://www.behance.net/MVPWorkshop"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            // src="/assets/images/logo1.svg"
            src={this.props.followIcons.behance}
            onMouseOver={e =>
              (e.currentTarget.src = this.props.followIcons.behanceHover)
            }
            onMouseOut={e =>
              (e.currentTarget.src = this.props.followIcons.behance)
            }
            alt=""
          />
        </a>{" "}
        <a
          href="https://dribbble.com/mvpworkshop"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src={this.props.followIcons.dribbble}
            onMouseOver={e =>
              (e.currentTarget.src = this.props.followIcons.dribbbleHover)
            }
            onMouseOut={e =>
              (e.currentTarget.src = this.props.followIcons.dribbble)
            }
            alt=""
          />
        </a>
      </div>
    );
  }
}
export default Follow;
