import React from "react";
import "./Community.scss";

function Community() {
  return (
    <div className="community--wrapper">
      <div className="community--inner d-flex justify-content-between align-items-center">
        <div className="community-left w-50">
          <h2>Community spotlight</h2>
          <p>
            We would love to see what you make out of it, so post your work on
            Dribbble, Behance, Twitter or wherever else by tagging
            <span className="comunity-tag"> #whollet</span> and we’ll return
            that love with some cool <span className="strong">swag</span>.
          </p>
          <p>
            Let’s collaborate together and get one step closer to the
            standardization of the crypto wallet user experience, and help
            facilitate mass adoption of properly developed products on
            blockchain.
          </p>
        </div>
        <div className="community-right w-50 d-flex justify-content-end align-items-center">
          <img src="/assets/images/community.svg" alt="" />
        </div>
      </div>
    </div>
  );
}
export default Community;
