import React, { Component } from "react";
import CountUp from "react-countup";
import "./Countup.scss";
import VisibilitySensor from "react-visibility-sensor";

class Countup extends Component {
  render() {
    return (
      <div className="count_up--wrapper">
        <h3>What’s inside the Whollet? </h3>

        <VisibilitySensor>
          {({ isVisible }) => {
            if (isVisible) {
              return (
                <div className="count_up-inner">
                  <CountUp start={0} end={70} duration={3} delay={0}>
                    {({ countUpRef, start }) => (
                      <div>
                        <span ref={countUpRef} />
                        <span>+</span>
                        <p>Screens</p>
                      </div>
                    )}
                  </CountUp>
                  <CountUp start={0} end={130} duration={4} delay={0}>
                    {({ countUpRef, start }) => (
                      <div>
                        <span ref={countUpRef} />
                        <span>+</span>
                        <p>Symbols</p>
                      </div>
                    )}
                  </CountUp>
                  <CountUp start={0} end={12} duration={5} delay={0}>
                    {({ countUpRef, start }) => (
                      <div>
                        <span ref={countUpRef} />
                        <p>Layouts</p>
                      </div>
                    )}
                  </CountUp>
                </div>
              );
            }
            return (
              <div className="count_up-inner">
                <CountUp start={14} end={14} duration={0} delay={0}>
                  {({ countUpRef, start }) => (
                    <div>
                      <span ref={countUpRef} />
                      <span>+</span>
                      <p>Screens</p>
                    </div>
                  )}
                </CountUp>
                <CountUp start={43} end={43} duration={0} delay={0}>
                  {({ countUpRef, start }) => (
                    <div>
                      <span ref={countUpRef} />
                      <span>+</span>
                      <p>Symbols</p>
                    </div>
                  )}
                </CountUp>
                <CountUp start={91} end={91} duration={0} delay={0}>
                  {({ countUpRef, start }) => (
                    <div>
                      <span ref={countUpRef} />
                      <span>+</span>
                      <p>Layouts</p>
                    </div>
                  )}
                </CountUp>
              </div>
            );
          }}
        </VisibilitySensor>

        <div className="count_up-footer">
          Tested, re-tested, and then re-re-tested. It works like a charm.
        </div>
      </div>
    );
  }
}

export default Countup;
