import React from "react";
import "./Hero.scss";
import Heroanimation from "../../components/Heroanimation/Heroanimation";
import Jumbotron from "react-bootstrap/Jumbotron";
import DButton from "../../components/DownloadButton/DownloadButton";

const downloadIcons = {
  sketch: "/assets/images/sketch-icon.svg",
  sketchHover: "/assets/images/sketch-icon-h.svg",
  figma: "/assets/images/figma-icon.svg",
  figmaHover: "/assets/images/figma-icon-h.svg"
};
function Hero() {
  return (
    <Jumbotron className="whoolet_hero">
      <div className="whoolet_hero--left-inner">
        <h1>A comprehensive crypto wallet UI kit</h1>
        <p>
          A completely <span>free</span> resource for product developers / UI
          designers all over the world who want to use and help develop
          standardized crypto wallet interactions and design patterns.
        </p>
        <DButton downloadIcons={downloadIcons} />

        <p>
          Made with love️ for blockchain community by humans from &nbsp;
          <a
            href="https://mvpworkshop.co/"
            target="_blank"
            rel="noopener noreferrer"
          >
            MVP Workshop
          </a>
        </p>
      </div>
      <Heroanimation />
    </Jumbotron>
  );
}

export default Hero;
