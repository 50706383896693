import React, { Component } from "react";
import "./Form.scss";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import axios from "axios";
import Select from "react-select";
import EmailSevice from "../../Services/Email";
import ReCAPTCHA from "react-google-recaptcha";
const { REACT_APP_RECAPTCHA_SITE_KEY } = process.env;

const Country = [
  { value: "Afghanistan", label: "Afghanistan" },
  { value: "Albania", label: "Albania" },
  { value: "Algeria", label: "Algeria" },
  { value: "American Samoa", label: "American Samoa" },
  { value: "Andorra", label: "Andorra" },
  { value: "Angola", label: "Angola" },
  { value: "Anguilla", label: "Anguilla" },
  { value: "Antarctica", label: "Antarctica" },
  { value: "Antigua and Barbuda", label: "Antigua and Barbuda" },
  { value: "Argentina", label: "Argentina" },
  { value: "Armenia", label: "Armenia" },
  { value: "Aruba", label: "Aruba" },
  { value: "Australia", label: "Australia" },
  { value: "Austria", label: "Austria" },
  { value: "Azerbaijan", label: "Azerbaijan" },
  { value: "Bahamas", label: "Bahamas" },
  { value: "Bahrain", label: "Bahrain" },
  { value: "Bangladesh", label: "Bangladesh" },
  { value: "Barbados", label: "Barbados" },
  { value: "Belarus", label: "Belarus" },
  { value: "Belgium", label: "Belgium" },
  { value: "Belize", label: "Belize" },
  { value: "Benin", label: "Benin" },
  { value: "Bermuda", label: "Bermuda" },
  { value: "Bhutan", label: "Bhutan" },
  { value: "Bolivia", label: "Bolivia" },
  {
    value: "Bonaire Sint Eustatius and Saba",
    label: "Bonaire Sint Eustatius and Saba"
  },
  { value: "Bosnia and Herzegovina", label: "Bosnia and Herzegovina" },
  { value: "Botswana", label: "Botswana" },
  { value: "Bouvet Island", label: "Bouvet Island" },
  { value: "Brazil", label: "Brazil" },
  {
    value: "British Indian Ocean Territory",
    label: "British Indian Ocean Territory"
  },
  { value: "British Virgin Islands", label: "British Virgin Islands" },
  { value: "Brunei", label: "Brunei" },
  { value: "Bulgaria", label: "Bulgaria" },
  { value: "Burkina Faso", label: "Burkina Faso" },
  { value: "Burundi", label: "Burundi" },
  { value: "Cabo Verde", label: "Cabo Verde" },
  { value: "Cambodia", label: "Cambodia" },
  { value: "Cameroon", label: "Cameroon" },
  { value: "Canada", label: "Canada" },
  { value: "Cayman Islands", label: "Cayman Islands" },
  { value: "Central African Republic", label: "Central African Republic" },
  { value: "Chad", label: "Chad" },
  { value: "Chile", label: "Chile" },
  { value: "China", label: "China" },
  { value: "Christmas Island", label: "Christmas Island" },
  { value: "Cocos Keeling Islands", label: "Cocos Keeling Islands" },
  { value: "Colombia", label: "Colombia" },
  { value: "Comoros", label: "Comoros" },
  { value: "Congo Republic", label: "Congo Republic" },
  { value: "Cook Islands", label: "Cook Islands" },
  { value: "Costa Rica", label: "Costa Rica" },
  { value: "Croatia", label: "Croatia" },
  { value: "Cuba", label: "Cuba" },
  { value: "Curaçao", label: "Curaçao" },
  { value: "Cyprus", label: "Cyprus" },
  { value: "Czechia", label: "Czechia" },
  { value: "DR Congo", label: "DR Congo" },
  { value: "Denmark", label: "Denmark" },
  { value: "Djibouti", label: "Djibouti" },
  { value: "Dominica", label: "Dominica" },
  { value: "Dominican Republic", label: "Dominican Republic" },
  { value: "Ecuador", label: "Ecuador" },
  { value: "Egypt", label: "Egypt" },
  { value: "El Salvador", label: "El Salvador" },
  { value: "Equatorial Guinea", label: "Equatorial Guinea" },
  { value: "Eritrea", label: "Eritrea" },
  { value: "Estonia", label: "Estonia" },
  { value: "Eswatini", label: "Eswatini" },
  { value: "Ethiopia", label: "Ethiopia" },
  { value: "Falkland Islands", label: "Falkland Islands" },
  { value: "Faroe Islands", label: "Faroe Islands" },
  { value: "Fiji", label: "Fiji" },
  { value: "Finland", label: "Finland" },
  { value: "France", label: "France" },
  { value: "French Guiana", label: "French Guiana" },
  { value: "French Polynesia", label: "French Polynesia" },
  {
    value: "French Southern Territories",
    label: "French Southern Territories"
  },
  { value: "Gabon", label: "Gabon" },
  { value: "Gambia", label: "Gambia" },
  { value: "Georgia", label: "Georgia" },
  { value: "Germany", label: "Germany" },
  { value: "Ghana", label: "Ghana" },
  { value: "Gibraltar", label: "Gibraltar" },
  { value: "Greece", label: "Greece" },
  { value: "Greenland", label: "Greenland" },
  { value: "Grenada", label: "Grenada" },
  { value: "Guadeloupe", label: "Guadeloupe" },
  { value: "Guam", label: "Guam" },
  { value: "Guatemala", label: "Guatemala" },
  { value: "Guernsey", label: "Guernsey" },
  { value: "Guinea", label: "Guinea" },
  { value: "Guinea-Bissau", label: "Guinea-Bissau" },
  { value: "Guyana", label: "Guyana" },
  { value: "Haiti", label: "Haiti" },
  {
    value: "Heard Island and McDonald Islands",
    label: "Heard Island and McDonald Islands"
  },
  { value: "Honduras", label: "Honduras" },
  { value: "Hong Kong", label: "Hong Kong" },
  { value: "Hungary", label: "Hungary" },
  { value: "Iceland", label: "Iceland" },
  { value: "India", label: "India" },
  { value: "Indonesia", label: "Indonesia" },
  { value: "Iran", label: "Iran" },
  { value: "Iraq", label: "Iraq" },
  { value: "Ireland", label: "Ireland" },
  { value: "Isle of Man", label: "Isle of Man" },
  { value: "Israel", label: "Israel" },
  { value: "Italy", label: "Italy" },
  { value: "Ivory Coast", label: "Ivory Coast" },
  { value: "Jamaica", label: "Jamaica" },
  { value: "Japan", label: "Japan" },
  { value: "Jersey", label: "Jersey" },
  { value: "Jordan", label: "Jordan" },
  { value: "Kazakhstan", label: "Kazakhstan" },
  { value: "Kenya", label: "Kenya" },
  { value: "Kiribati", label: "Kiribati" },
  { value: "Kosovo", label: "Kosovo" },
  { value: "Kuwait", label: "Kuwait" },
  { value: "Kyrgyzstan", label: "Kyrgyzstan" },
  { value: "Laos", label: "Laos" },
  { value: "Latvia", label: "Latvia" },
  { value: "Lebanon", label: "Lebanon" },
  { value: "Lesotho", label: "Lesotho" },
  { value: "Liberia", label: "Liberia" },
  { value: "Libya", label: "Libya" },
  { value: "Liechtenstein", label: "Liechtenstein" },
  { value: "Lithuania", label: "Lithuania" },
  { value: "Luxembourg", label: "Luxembourg" },
  { value: "Macao", label: "Macao" },
  { value: "Madagascar", label: "Madagascar" },
  { value: "Malawi", label: "Malawi" },
  { value: "Malaysia", label: "Malaysia" },
  { value: "Maldives", label: "Maldives" },
  { value: "Mali", label: "Mali" },
  { value: "Malta", label: "Malta" },
  { value: "Marshall Islands", label: "Marshall Islands" },
  { value: "Martinique", label: "Martinique" },
  { value: "Mauritania", label: "Mauritania" },
  { value: "Mauritius", label: "Mauritius" },
  { value: "Mayotte", label: "Mayotte" },
  { value: "Mexico", label: "Mexico" },
  { value: "Micronesia", label: "Micronesia" },
  { value: "Moldova", label: "Moldova" },
  { value: "Monaco", label: "Monaco" },
  { value: "Mongolia", label: "Mongolia" },
  { value: "Montenegro", label: "Montenegro" },
  { value: "Montserrat", label: "Montserrat" },
  { value: "Morocco", label: "Morocco" },
  { value: "Mozambique", label: "Mozambique" },
  { value: "Myanmar", label: "Myanmar" },
  { value: "Namibia", label: "Namibia" },
  { value: "Nauru", label: "Nauru" },
  { value: "Nepal", label: "Nepal" },
  { value: "Netherlands", label: "Netherlands" },
  { value: "Netherlands Antilles", label: "Netherlands Antilles" },
  { value: "New Caledonia", label: "New Caledonia" },
  { value: "New Zealand", label: "New Zealand" },
  { value: "Nicaragua", label: "Nicaragua" },
  { value: "Niger", label: "Niger" },
  { value: "Nigeria", label: "Nigeria" },
  { value: "Niue", label: "Niue" },
  { value: "Norfolk Island", label: "Norfolk Island" },
  { value: "North Korea", label: "North Korea" },
  { value: "North Macedonia", label: "North Macedonia" },
  { value: "Northern Mariana Islands", label: "Northern Mariana Islands" },
  { value: "Norway", label: "Norway" },
  { value: "Oman", label: "Oman" },
  { value: "Pakistan", label: "Pakistan" },
  { value: "Palau", label: "Palau" },
  { value: "Palestine", label: "Palestine" },
  { value: "Panama", label: "Panama" },
  { value: "Papua New Guinea", label: "Papua New Guinea" },
  { value: "Paraguay", label: "Paraguay" },
  { value: "Peru", label: "Peru" },
  { value: "Philippines", label: "Philippines" },
  { value: "Pitcairn Islands", label: "Pitcairn Islands" },
  { value: "Poland", label: "Poland" },
  { value: "Portugal", label: "Portugal" },
  { value: "Puerto Rico", label: "Puerto Rico" },
  { value: "Qatar", label: "Qatar" },
  { value: "Romania", label: "Romania" },
  { value: "Russia", label: "Russia" },
  { value: "Rwanda", label: "Rwanda" },
  { value: "Réunion", label: "Réunion" },
  { value: "Saint Barthélemy", label: "Saint Barthélemy" },
  { value: "Saint Helena", label: "Saint Helena" },
  { value: "Saint Lucia", label: "Saint Lucia" },
  { value: "Saint Martin", label: "Saint Martin" },
  { value: "Saint Pierre and Miquelon", label: "Saint Pierre and Miquelon" },
  { value: "Samoa", label: "Samoa" },
  { value: "San Marino", label: "San Marino" },
  { value: "Saudi Arabia", label: "Saudi Arabia" },
  { value: "Senegal", label: "Senegal" },
  { value: "Serbia", label: "Serbia" },
  { value: "Serbia and Montenegro", label: "Serbia and Montenegro" },
  { value: "Seychelles", label: "Seychelles" },
  { value: "Sierra Leone", label: "Sierra Leone" },
  { value: "Singapore", label: "Singapore" },
  { value: "Sint Maarten", label: "Sint Maarten" },
  { value: "Slovakia", label: "Slovakia" }
];

const Industry = [
  {
    value: "Investment & Venture Capital",
    label: "Investment & Venture Capital"
  },
  {
    value: "Financial Services & Technology",
    label: "Financial Services & Technology"
  },
  { value: "Media & Entertainment", label: "Media & Entertainment" },
  { value: "Gaming & eSports", label: "Gaming & eSports" },
  { value: "Wholesale & Retail", label: "Wholesale & Retail" },
  { value: "Education", label: "Education" },
  { value: "Writing & Publishing", label: "Writing & Publishing" },
  { value: "Government & IGOs", label: "Government & IGOs" },
  { value: "Pro Sports & Recreation", label: "Pro Sports & Recreation" },
  { value: "Logistics & Procurement", label: "Logistics & Procurement" },
  { value: "Non-profit & Civic Society", label: "Non-profit & Civic Society" },
  { value: "Hardware & Electronics", label: "Hardware & Electronics" },
  { value: "Corporate & Retail Banking", label: "Corporate & Retail Banking" },
  { value: "Accounting & Auditing", label: "Accounting & Auditing" },
  { value: "Energy & Mining", label: "Energy & Mining" },
  { value: "Aviation & Aerospace", label: "Aviation & Aerospace" },
  { value: "Software Development", label: "Software Development" },
  { value: "Food & Beverages", label: "Food & Beverages" },
  { value: "Medical & Health Care", label: "Medical & Health Care" },
  { value: "Marketing & Sales", label: "Marketing & Sales" },
  { value: "Construction", label: "Construction" },
  { value: "Real Estate", label: "Real Estate" },
  { value: "Transportation", label: "Transportation" },
  { value: "ICT", label: "ICT" },
  { value: "Other", label: "Other" }
];
const initialState = {
  industry: { value: null, options: Industry },
  email: "",
  fname: "",
  lname: "",
  job: "",
  company: "",
  country: { value: null, options: Country },
  phone: "",
  industryError: "",
  emailError: "",
  fnameError: "",
  lnameError: "",
  jobError: "",
  companyError: "",
  countryError: "",
  phoneError: "",
  isSending: false,
  isCaptchaExpired: true
};
const successMessage = "";

class SiteForm extends Component {
  constructor(props) {
    super(props);

    this.recaptchaRef = React.createRef();
    this.state = initialState;
    this.country = {
      countryCode: ""
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  setValue = (value, field) => {
    this.setState(prevState => ({
      [field]: {
        ...prevState[field],
        value
      }
    }));
  };

  handleIndustryChange = item => {
    this.setValue(item, "industry");
  };

  handleCountryChange = item => {
    this.setValue(item, "country");
  };

  getGeoInfo = () => {
    axios
      .get("https://ipapi.co/json/")
      .then(response => {
        let data = response.data;
        this.setState({
          countryCode: data.country_code.toLowerCase()
        });
      })
      .catch(error => {
        console.log(error);
      });
  };

  componentDidMount() {
    this.getGeoInfo();
  }

  handleChange(event) {
    const target = event.target;
    const type = target.type;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }
  // validation
  //Sanitize string
  validString(input) {
    return !/[\\/&;]/.test(input);
  }
  validEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  }
  validate = () => {
    let industryError = "";
    let emailError = "";
    let fnameError = "";
    let lnameError = "";
    let jobError = "";
    let companyError = "";
    let countryError = "";
    let phoneError = "";

    if (!this.validEmail(this.state.email)) {
      fnameError =
        "One or more fields have an error. Please check and try again.";
    }
    if (!this.validString(this.state.fname)) {
      fnameError =
        "One or more fields have an error. Please check and try again.";
    }
    if (!this.validString(this.state.lname)) {
      lnameError =
        "One or more fields have an error. Please check and try again.";
    }
    if (!this.validString(this.state.job)) {
      jobError =
        "One or more fields have an error. Please check and try again.";
    }
    if (!this.validString(this.state.company)) {
      companyError =
        "One or more fields have an error. Please check and try again.";
    }

    if (!this.state.industry.value) {
      industryError = "Please sellect your industry";
    }
    if (!this.state.email) {
      emailError = "Please enter your email";
    }
    if (!this.state.fname) {
      fnameError = "Please fill all required fields";
    }
    if (!this.state.lname) {
      lnameError = "Please fill all required fields";
    }
    if (!this.state.job) {
      jobError = "Please fill all required fields";
    }
    if (!this.state.company) {
      companyError = "Please fill all required fields";
    }
    if (!this.state.country.value) {
      countryError = "Please select your country";
    }
    if (!this.state.phone) {
      phoneError = "Please fill all required fields";
    }

    if (
      industryError ||
      emailError ||
      fnameError ||
      lnameError ||
      jobError ||
      companyError ||
      countryError ||
      phoneError
    ) {
      this.setState({
        industryError,
        emailError,
        fnameError,
        lnameError,
        jobError,
        companyError,
        countryError,
        phoneError
      });
      return false;
    }

    return true;
  };
  onCaptchaExpiration() {
    this.setState({ isCaptchaExpired: true });
  }

  async sendMail() {
    const recaptchaToken = this.recaptchaRef.current.getValue();
    try {
      const resp = await EmailSevice.sendWholletMail({
        recaptchaToken: recaptchaToken,
        industry: this.state.industry.value.value,
        email: this.state.email,
        fname: this.state.fname,
        lname: this.state.lname,
        job: this.state.job,
        company: this.state.company,
        country: this.state.country.value.value,
        phone: this.state.phone
      });

      this.setState({
        successMessage: "Your form has been successfully submitted!",
        messageClass: "success--message"
      });
      this.setState({ ...initialState });
    } catch (error) {
      console.log(
        "There was an error trying to send your message. Please try again later."
      );
      this.setState({
        successMessage:
          "There was an error trying to send your message. Please try again later.",
        messageClass: "gen-error--message"
      });
    }
  }

  onCaptchaValidated = () => {
    this.sendMail();
    this.setState({ isCaptchaExpired: false });
  };

  executeCaptcha = () => {
    if (this.recaptchaRef && this.recaptchaRef.current) {
      if (this.state.isCaptchaExpired) {
        this.recaptchaRef.current.execute();
      } else {
        this.sendMail();
      }
    }
  };
  onCaptchaError = () => {
    this.setState({
      successMessage: "There was an error with ReCpatcha.",
      messageClass: "gen-error--message"
    });
  };

  async handleSubmit(event) {
    event.preventDefault();

    const isValid = this.validate();

    if (isValid) {
      this.setState({ isSending: true });

      this.executeCaptcha();
    } else {
      console.log(
        "There was an error trying to send your message. Please try again later."
      );
    }
  }

  //handle select
  handleSelectIndustry = selectedOption => {
    let industry = selectedOption.value;
    this.setState({ industry });
  };
  handleSelectCountry = selectedOption => {
    let country = selectedOption.value;
    this.setState({ country });
  };

  render() {
    const selectedOptionIndustry = this.state.industry;
    const selectedOptionCountry = this.state.country;

    return (
      <React.Fragment>
        <Form
          className="whollet-consultation--form"
          onSubmit={this.handleSubmit}
        >
          <Form.Group>
            <Select
              className="industry-select-container"
              placeholder="Select Your Industry"
              value={selectedOptionIndustry.value}
              onChange={this.handleIndustryChange}
              options={selectedOptionIndustry.options}
              theme={theme => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary25: "#edeff2",
                  primary: "#347af0"
                }
              })}
            />
            <div className="form-error--message">
              {this.state.industryError}
            </div>
          </Form.Group>

          <Form.Group controlId="exampleForm.ControlInput1">
            <Form.Control
              type="email"
              name="email"
              value={this.state.email}
              onChange={this.handleChange}
              placeholder="Email *"
            />
            <div className="form-error--message">{this.state.emailError}</div>
          </Form.Group>
          <Form.Group controlId="exampleForm.ControlInput2">
            <Form.Control
              type="text"
              name="fname"
              value={this.state.fname}
              onChange={this.handleChange}
              placeholder="First Name *"
            />
            <div className="form-error--message">{this.state.fnameError}</div>
          </Form.Group>
          <Form.Group controlId="exampleForm.ControlInput3">
            <Form.Control
              type="text"
              name="lname"
              value={this.state.lname}
              onChange={this.handleChange}
              placeholder="Last Name *"
            />
            <div className="form-error--message">{this.state.lnameError}</div>
          </Form.Group>
          <Form.Group controlId="exampleForm.ControlInput4">
            <Form.Control
              type="text"
              name="job"
              value={this.state.job}
              onChange={this.handleChange}
              placeholder="Job Title *"
            />
            <div className="form-error--message">{this.state.jobError}</div>
          </Form.Group>
          <Form.Group controlId="exampleForm.ControlInput5">
            <Form.Control
              type="text"
              name="company"
              value={this.state.company}
              onChange={this.handleChange}
              placeholder="Company Name *"
            />
            <div className="form-error--message">{this.state.companyError}</div>
          </Form.Group>
          <Form.Group>
            <Select
              placeholder="Country/Region"
              value={selectedOptionCountry.value}
              onChange={this.handleCountryChange}
              options={selectedOptionCountry.options}
              theme={theme => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary25: "#edeff2",
                  primary: "#347af0"
                }
              })}
            />
            <div className="form-error--message">{this.state.countryError}</div>
          </Form.Group>

          <PhoneInput
            inputProps={{
              name: "phone",
              required: true,
              autoFocus: true
            }}
            placeholder="Phone Number"
            type="tel"
            country={this.state.countryCode}
            value={this.state.phone}
            onBlur={this.handleChange}
          />
          <div className="form-error--message">{this.state.phoneError}</div>
          <Button className="form-submit" variant="primary" type="submit">
            Send
          </Button>
          <div className={this.state.messageClass}>
            {this.state.successMessage}
          </div>
        </Form>

        <ReCAPTCHA
          ref={this.recaptchaRef}
          size="invisible"
          sitekey={REACT_APP_RECAPTCHA_SITE_KEY}
          onChange={this.onCaptchaValidated}
          onErrored={this.onCaptchaError}
          onExpired={this.onCaptchaExpiration}
        />
      </React.Fragment>
    );
  }
}

export default SiteForm;
