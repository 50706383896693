import React from "react";
import "./ModalButton.scss";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import Button from "react-bootstrap/Button";
import Modal from "../Modal/Modal";

function ModalButton() {
  const [modalShow, setModalShow] = React.useState(false);

  return (
    <ButtonToolbar>
      <Button
        className="button-desc"
        variant="primary"
        onClick={() => setModalShow(true)}
      >
        Claim consultation
        <img src="/assets/images/message-circle.svg" alt="" />
      </Button>

      <Modal show={modalShow} onHide={() => setModalShow(false)} />
    </ButtonToolbar>
  );
}
export default ModalButton;
