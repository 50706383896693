import React from "react";
import "./TeamDesc.scss";

function TeamDesc() {
  return (
    <div
      id="preview"
      className="team-section-down d-flex justify-content-center align-items-center"
    >
      <div className="team-section-down_left w-50">
        <h2>Everything your crypto wallet needs</h2>
      </div>
      <div className="team-section-down_right w-50">
        <p>
          Based on experience from many different designers in the industry, we
          have created various different layouts to help you bring your next
          project to life. You can find layouts that cover flows like KYC,
          Depositing, Withdrawing, Exchanging, Transaction tracking, and many
          more…
        </p>
      </div>
    </div>
  );
}
export default TeamDesc;
