import React from "react";
import "./Sets.scss";
import Subheader from "../../components/Subheader/Subheader";

function Sets() {
  return (
    <div
      id="about"
      className="sets-wrapper d-flex flex-wrap justify-content-center"
    >
      <div className="font-set-wrapper sets-item d-flex  justify-content-end align-items-center">
        <div className="font-set">
          <h3>Titilium Web</h3>
        </div>
      </div>
      <div className="sets-item d-flex  justify-content-around align-items-center">
        <div>
          <Subheader subheader="Free font" />
          <p>
            Inside the kit, you will find a free font -
            <a
              href="https://fonts.google.com/specimen/Titillium+Web"
              target="_blank"
              rel="noopener noreferrer"
            >
              Titillium Web
            </a>
            . We love it, and you can always change it to fit your project
            needs. After all, don’t have to use the kit as it is - feel free to
            experiment, change and improve it as you wish.
          </p>
        </div>
      </div>
      <div className="sets-item d-flex  justify-content-end align-items-center">
        <div>
          <Subheader subheader="Delesign illustrations" />
          <p>
            <a
              href="https://delesign.com/free-designs/graphics/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Delesign
            </a>{" "}
            is one of those cool crews which love to share free assets. You can
            use some of those awesome illustrations and adapt them to the
            branding of your project.
          </p>
        </div>
      </div>
      <div className="sets-item support-team d-flex  justify-content-around align-items-center">
        <img src="/assets/images/support-team.svg" alt="" />
      </div>
      <div className="sets-item icon-set d-flex  justify-content-end align-items-center">
        <img src="/assets/images/icon-set.svg" alt="" />
      </div>
      <div className="sets-item d-flex  justify-content-around align-items-center">
        <div>
          <Subheader subheader="A wonderful icon set" />
          <p>
            They are bubbly, free to use and oh so beautiful! Aaaand they come
            from{" "}
            <a
              href="https://feathericons.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Feather Icons
            </a>{" "}
            set, made by{" "}
            <a
              href="https://twitter.com/colebemis"
              target="_blank"
              rel="noopener noreferrer"
            >
              @colebemis
            </a>
            . How cool is that?
          </p>
        </div>
      </div>
    </div>
  );
}

export default Sets;
