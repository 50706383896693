import axios from "axios";
const {
  REACT_APP_EMAILJS_API_URL,
  REACT_APP_EMAILJS_API_VERSION
} = process.env;

export const EmailJSAPI = axios.create({
  baseURL: `${REACT_APP_EMAILJS_API_URL}/api/${REACT_APP_EMAILJS_API_VERSION}`
});

EmailJSAPI.interceptors.response.use(
  response => {
    return response;
  },
  async error => {
    if (error.response) {
    }

    return Promise.reject(error);
  }
);
