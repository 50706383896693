import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import Header from "./containers/Header/Header";
import Hero from "./containers/Hero/Hero";
import Countup from "./containers/Countup/Countup";
import Sets from "./containers/Sets/Sets";
import Wallet from "./containers/Wallet/Wallet";
import Team from "./containers/Team/Team";
import CarouselEl from "./containers/Carousel-container/Carousel";
import PhoneCard from "./containers/PhoneCards/PhoneCards";
import Examples from "./containers/Examples/Examples";
import Community from "./containers/Community/Community";
import Download from "./containers/Download/Download";
import Footer from "./containers/Footer/Footer";

class App extends Component {
  render() {
    return (
      <div className="App">
        <Header />
        <Hero />
        <Countup />
        <Sets />
        <Wallet />
        <CarouselEl />
        <Team />
        <PhoneCard />
        <Examples />
        <Download />
        <Community />
        <Footer />
      </div>
    );
  }
}
export default App;
