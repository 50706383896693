import React from "react";
import "./Carousel.scss";
import CarouselBootstrap from "../../components/Carousel/CarouselBootstrap";
import CarouselStatic from "../../components/CarouselStatic/CarouselStatic";

class CarouselEl extends React.Component {
  render() {
    return (
      <div className="carousel--wrapper">
        <CarouselStatic />
        <div className="carousel--right-carousel">
          <CarouselBootstrap />
        </div>
      </div>
    );
  }
}

export default CarouselEl;
