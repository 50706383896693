import React from "react";
import { Parallax } from "react-parallax";
import "./Wallet.scss";

const Wallet = () => (
  <div>
    <div className="wallet-mob d-flex flex-column">
      <div className="wallet-right-mob d-flex flex-column justify-content-center align-items-start">
        <div>
          <h3>Make some good-looking wallets</h3>
          <p>
            Find a bunch of UI elements for your next crypto wallet project.
            Some of them may look familiar since many projects such as Coinbase,
            Argent, Celsius, and others are using similar information
            architecture and interaction patterns. Our team from MVP Workshop
            gathered the best practices (some of which were developed by us) for
            you to use and customize freely.
          </p>
        </div>
      </div>
    </div>
    {/* -----basic config-----*/}
    <Parallax
      blur={0}
      bgImage={require("../../images/parallax-background.jpg")}
      bgImageAlt="wallets"
      strength={400}
      className="firstone-parallax"
    >
      <div className="wallet-right d-flex flex-column justify-content-center align-items-start">
        <h3>Make some good-looking wallets </h3>
        <p>
          Find a bunch of UI elements for your next crypto wallet project. Some
          of them may look familiar since many projects such as Coinbase,
          Argent, Celsius, and others are using similar information architecture
          and interaction patterns. Our team from MVP Workshop gathered the best
          practices (some of which were developed by us) for you to use and
          customize freely.
        </p>
      </div>
    </Parallax>
    <Parallax
      blur={0}
      bgImage={require("../../images/paralax-phones-mob.png")}
      bgImageAlt="wallets"
      strength={400}
      className="secondstone-parallax"
      bgImageSizes={"width:100%"}
    >
      <div className="wallet-right d-flex flex-column justify-content-center align-items-start">
        <h3>Make some good-looking wallets </h3>
        <p>
          Find a bunch of UI elements for your next crypto wallet project. Some
          of them may look familiar since many projects such as Coinbase,
          Argent, Celsius, and others are using similar information architecture
          and interaction patterns. Our team from MVP Workshop gathered the best
          practices (some of which were developed by us) for you to use and
          customize freely.
        </p>
      </div>
    </Parallax>
  </div>
);
export default Wallet;
