import React from "react";
import Carousel from "react-bootstrap/Carousel";
import "./CarouselBootstrap.scss";
import Svg from "../MorpheSVG/MorpheSvg";

function CarouselBootstrap() {
  return (
    <Carousel interval="3000" controls="false">
      <Carousel.Item>
        <div className="carousel--item-wrapper">
          <div className="carousel--item-left">
            <div>
              <h2>01</h2>
              <h3>Editable</h3>
              <p>Show how UI elements change color</p>
            </div>
          </div>

          <div className="carousel--item-right">
            <Svg class="morph" width="645" height="650" viewBox="0 0 645 650" />
            <div className="carousel--img-wrapper carousel--img-first">
              <div className="img2">
                <img
                  src="/assets/images/img2.png"
                  srcSet="/assets/images/img2.png 1x, /assets/images/img2@2x.png 2x"
                  alt=""
                />
              </div>
              <div className="img1">
                <img
                  src="/assets/images/img1.png"
                  srcSet="/assets/images/img1.png 1x, /assets/images/img1@2x.png 2x"
                  alt=""
                />
              </div>
              <div className="img3">
                <img
                  src="/assets/images/img3.png"
                  srcSet="/assets/images/img3.png 1x, /assets/images/img3@2x.png 2x"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </Carousel.Item>

      <Carousel.Item>
        <div className="carousel--item-wrapper">
          <div className="carousel--item-left">
            <div>
              <h2>02</h2>
              <h3>Scalable</h3>
              <p>Show how UI elements change size</p>
            </div>
          </div>

          <div className="carousel--item-right">
            <Svg
              class="morph2"
              width="477"
              height="488"
              viewBox="0 0 477 488"
            />
            <div className="carousel--img-wrapper carousel--img-second">
              <div className="img4 img-fluid">
                <img
                  src="/assets/images/img4.png"
                  srcSet="/assets/images/img4.png 1x, /assets/images/img4@2x.png 2x"
                  alt=""
                />
              </div>

              <div className="img5 img-fluid">
                <img
                  src="/assets/images/img5.png"
                  srcSet="/assets/images/img5.png 1x, /assets/images/img5@2x.png 2x"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </Carousel.Item>
      <Carousel.Item>
        <div className="carousel--item-wrapper">
          <div className="carousel--item-left">
            <div>
              <h2>03</h2>
              <h3>Well-Organized</h3>
              <p>Show layers and how they are named</p>
            </div>
          </div>

          <div className="carousel--item-right">
            <Svg
              class="morph1"
              width="668"
              height="670"
              viewBox="0 0 668 670"
            />
            <div className="carousel--img-wrapper carousel--img-third">
              <div className="img6">
                <img
                  src="/assets/images/img6.png"
                  srcSet="/assets/images/img6.png 1x, /assets/images/img6@2x.png 2x"
                  alt=""
                />
              </div>
              <div className="img7">
                <img
                  src="/assets/images/img7.png"
                  srcSet="/assets/images/img7.png 1x, /assets/images/img7@2x.png 2x"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </Carousel.Item>
    </Carousel>
  );
}

export default CarouselBootstrap;
