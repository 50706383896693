import React from "react";
import "./Header.css";
import Navigation from "../../components/Navigation/Navigation";

function Header() {
  return (
    <header className="App-header">
      <Navigation />
    </header>
  );
}

export default Header;
