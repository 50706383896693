import React from "react";
import "./PhoneCards.scss";
import "animate.css/animate.min.css";
import ScrollAnimation from "react-animate-on-scroll";

function PhoneCards() {
  return (
    <div className="phone-cards--outher">
      <ScrollAnimation
        className="scroll-phone-cards desk-phone-cards"
        animateIn="fadeInUp"
        animateOnce={true}
      >
        <div className="phone-cards--wrapper d-flex flex-wrap justify-content-center">
          <div className="phone-cards--col d-flex justify-content-start  flex-column">
            <img
              src="/assets/images/imgp1.png"
              srcSet="/assets/images/imgp1.png 1x, /assets/images/imgp1@2x.png 2x"
              alt=""
            />

            <img
              src="/assets/images/imgp6.png"
              srcSet="/assets/images/imgp6.png 1x, /assets/images/imgp6@2x.png 2x"
              alt=""
            />
          </div>
          <div className="phone-cards--col  d-flex justify-content-start flex-column up-50">
            <img
              src="/assets/images/imgp2.png"
              srcSet="/assets/images/imgp2.png 1x, /assets/images/imgp2@2x.png 2x"
              alt=""
            />

            <img
              src="/assets/images/imgp7.png"
              srcSet="/assets/images/imgp7.png 1x, /assets/images/imgp7@2x.png 2x"
              alt=""
            />
          </div>
          <div className="phone-cards--col  d-flex justify-content-start flex-column">
            <img
              src="/assets/images/imgp3.png"
              srcSet="/assets/images/imgp3.png 1x, /assets/images/imgp3@2x.png 2x"
              alt=""
            />

            <img
              src="/assets/images/imgp8.png"
              srcSet="/assets/images/imgp8.png 1x, /assets/images/imgp8@2x.png 2x"
              alt=""
            />
          </div>
          <div className="phone-cards--col d-flex justify-content-start  flex-column up-112">
            <img
              src="/assets/images/imgp4.png"
              srcSet="/assets/images/imgp4.png 1x, /assets/images/imgp4@2x.png 2x"
              alt=""
            />

            <img
              src="/assets/images/imgp9.png"
              srcSet="/assets/images/imgp9.png 1x, /assets/images/imgp9@2x.png 2x"
              alt=""
            />
          </div>
          <div className="phone-cards--col d-flex justify-content-start flex-column">
            <img
              src="/assets/images/imgp5.png"
              srcSet="/assets/images/imgp5.png 1x, /assets/images/imgp5@2x.png 2x"
              alt=""
            />

            <img
              src="/assets/images/imgp10.png"
              srcSet="/assets/images/imgp10.png 1x, /assets/images/imgp10@2x.png 2x"
              alt=""
            />
          </div>
        </div>
      </ScrollAnimation>

      <ScrollAnimation
        className="scroll-phone-cards mob-phone-cards"
        animateIn="fadeInUp"
        animateOnce={true}
      >
        <div className="phone-cards--wrapper d-flex flex-wrap justify-content-center">
          <div className="phone-cards--col d-flex justify-content-start  flex-column">
            <img
              src="/assets/images/imgp1.png"
              srcSet="/assets/images/imgp1.png 1x, /assets/images/imgp1@2x.png 2x"
              alt=""
            />

            <img
              src="/assets/images/imgp6.png"
              srcSet="/assets/images/imgp6.png 1x, /assets/images/imgp6@2x.png 2x"
              alt=""
            />

            <img
              src="/assets/images/imgp3.png"
              srcSet="/assets/images/imgp3.png 1x, /assets/images/imgp3@2x.png 2x"
              alt=""
            />

            <img
              src="/assets/images/imgp10.png"
              srcSet="/assets/images/imgp10.png 1x, /assets/images/imgp10@2x.png 2x"
              alt=""
            />
          </div>
          <div className="phone-cards--col  d-flex justify-content-start flex-column up-90">
            <img
              src="/assets/images/imgp2.png"
              srcSet="/assets/images/imgp2.png 1x, /assets/images/imgp2@2x.png 2x"
              alt=""
            />

            <img
              src="/assets/images/imgp7.png"
              srcSet="/assets/images/imgp7.png 1x, /assets/images/imgp7@2x.png 2x"
              alt=""
            />

            <img
              src="/assets/images/imgp4.png"
              srcSet="/assets/images/imgp4.png 1x, /assets/images/imgp4@2x.png 2x"
              alt=""
            />

            <img
              src="/assets/images/imgp9.png"
              srcSet="/assets/images/imgp9.png 1x, /assets/images/imgp9@2x.png 2x"
              alt=""
            />
          </div>
          {/* <div className="phone-cards--col up-112 d-flex justify-content-start flex-column">
            <img
              className="up179"
              src="/assets/images/imgp9.png"
              srcSet="/assets/images/imgp9.png 1x, /assets/images/imgp9@2x.png 2x"
              alt=""
            />

            <img
              src="/assets/images/imgp10.png"
              srcSet="/assets/images/imgp10.png 1x, /assets/images/imgp10@2x.png 2x"
              alt=""
            />
          </div> */}
        </div>
      </ScrollAnimation>
    </div>
  );
}

export default PhoneCards;
