import React from "react";
import "./Examples.scss";

function Examples() {
  return (
    <div className="examples-wrapper d-flex flex-wrap justify-content-between">
      <div className="animated slideInUp">
        <img
          src="/assets/images/colors.png"
          srcSet="/assets/images/colors.png 1x, /assets/images/colors@2x.png 2x"
          alt=""
        />
      </div>
      <div className="align-self-start">
        <img
          src="/assets/images/buttons.png"
          srcSet="/assets/images/buttons.png 1x, /assets/images/buttons@2x.png 2x"
          alt=""
        />
      </div>
      <div>
        <img src="/assets/images/typo.png" alt="" />
      </div>
      <div className="align-self-start">
        <img src="/assets/images/buttons-buttons.png" alt="" />
        <img
          className="ex-none"
          src="/assets/images/buttons-buttons.png"
          srcSet="/assets/images/buttons-buttons.png 1x, /assets/images/buttons-buttons@2x.png 2x"
          alt=""
        />
      </div>
      <div className="mob-item">
        <img
          src="/assets/images/form.png"
          srcSet="/assets/images/form.png 1x, /assets/images/form@2x.png 2x"
          alt=""
        />
      </div>
    </div>
  );
}
export default Examples;
