import { EmailJSAPI } from "../Util/Api";
const {
  REACT_APP_EMAILJS_USER_ID,
  REACT_APP_EMAILJS_SERVICE_ID,
  REACT_APP_EMAILJS_WHOLLET_TEMPLATE_ID
} = process.env;

class EmailService {
  serviceId;
  userId;
  TemplateId;

  constructor() {
    this.serviceId = REACT_APP_EMAILJS_SERVICE_ID;
    this.userId = REACT_APP_EMAILJS_USER_ID;
    this.TemplateId = REACT_APP_EMAILJS_WHOLLET_TEMPLATE_ID;
  }

  sendWholletMail = params => {
    const template_params = {
      "g-recaptcha-response": params.recaptchaToken,
      industry: params.industry,
      email: params.email,
      fname: params.fname,
      lname: params.lname,
      job: params.job,
      company: params.company,
      country: params.country,
      phone: params.phone
    };

    const data = {
      user_id: this.userId,
      service_id: this.serviceId,
      template_id: this.TemplateId,
      template_params
    };

    return EmailJSAPI.post("email/send", data, {
      headers: { "Content-type": "application/json" }
    }).then(response => response.data);
  };
}

export default new EmailService();
