import React from "react";
import "./MorpheSvg.scss";

class MorpheSvg extends React.Component {
  render() {
    return (
      <svg
        className={this.props.class}
        id="mrp"
        width={this.props.width}
        height={this.props.height}
        viewBox={this.props.viewBox}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="" fill="#E0E9F8" />
      </svg>
    );
  }
}

export default MorpheSvg;
