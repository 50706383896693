import React, { Component } from "react";
import "./MenuButton.scss";
import Navbar from "react-bootstrap/Navbar";

class MenuButton extends Component {
  state = {
    active: false
  };
  render() {
    return (
      <Navbar.Toggle aria-controls="responsive-navbar-nav">
        <div
          id="nav-icon"
          className={this.state.active ? "open" : "closed"}
          onClick={() => this.setState({ active: !this.state.active })}
        >
          <span className="navicon-line--first"></span>
          <span className="navicon-line--second"></span>
          <span className="navicon-line--third"></span>
        </div>
      </Navbar.Toggle>
    );
  }
}
// function MenuButton() {
//   return (
// <Navbar.Toggle aria-controls="responsive-navbar-nav">
//   <div
//     id="nav-icon"
//     className={this.state.active && "active"}
//     onClick={() => this.setState({ active: !this.state.active })}
//   >
//     <span className="navicon-line--first"></span>
//     <span className="navicon-line--second"></span>
//     <span className="navicon-line--third"></span>
//   </div>
// </Navbar.Toggle>
//   );
// }

export default MenuButton;
