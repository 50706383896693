import React from "react";
import "./Modal.scss";
import Modal from "react-bootstrap/Modal";
//import Button from "react-bootstrap/Button";
import Form from "../../containers/Form/Form";

function centerModal(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          <h3>Get free consultation</h3>
          <h5>Please chose your industry and fill in your details below:</h5>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form />
      </Modal.Body>
      {/* <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer> */}
    </Modal>
  );
}
export default centerModal;
