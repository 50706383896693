import React from "react";
import "./Download.scss";
import DButton from "../../components/DownloadButton/DownloadButton";

const downloadIcons = {
  sketch: "/assets/images/sketch-icon.svg",
  sketchHover: "/assets/images/sketch-icon-h.svg",
  figma: "/assets/images/figma-icon.svg",
  figmaHover: "/assets/images/figma-icon-h.svg"
};

function Download() {
  return (
    <div
      id="download"
      className="download--wrapper d-flex flex-wrap justify-content-start"
    >
      <div className="download--inner d-flex justify-content-start">
        <div className="download-img--wrapper d-flex justify-content-end">
          <img src="/assets/images/download-img.svg" alt="" />
        </div>
        <div className="download-button">
          <DButton downloadIcons={downloadIcons} />
        </div>
      </div>
    </div>
  );
}

export default Download;
