import React from 'react'
import './Navigation.scss'
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import MenuButton from '../MenuButton/MenuButton'
import Dropdown from 'react-bootstrap/Dropdown'
import Copy from '../Copy/Copy'
import Follow from '../Follow/Follow'
const followIcons = {
  behance: '/assets/images/logo1-hover.svg',
  behanceHover: '/assets/images/logo1.svg',
  dribbble: '/assets/images/logo2-hover.svg',
  dribbbleHover: '/assets/images/logo2.svg',
}
function Navigation() {
  return (
    <Navbar collapseOnSelect expand="lg" className="whollet_nav">
      <Navbar.Brand href="#home">Whollet</Navbar.Brand>
      <MenuButton />
      <Navbar.Collapse
        id="responsive-navbar-nav"
        className="justify-content-end"
      >
        <Nav className="">
          <Nav.Link href="#about">What's inside</Nav.Link>
          <Nav.Link href="#preview">Preview</Nav.Link>
          <Nav.Link href="#sharelove">About us</Nav.Link>
          <Nav.Link href="#download">Download</Nav.Link>
          <Dropdown>
            <Dropdown.Toggle
              variant="success"
              id="dropdown-basic"
              className="btn-transparent"
            >
              <img src="/assets/images/share-btn.svg" alt="share" />
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item
                href="https://www.linkedin.com/shareArticle?url=https://whollet.io/&text=A completely free, comprehensive crypto wallet UI kit for developers / UI designers who want to develop standardized interactions and patterns.&mini=true"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src="/assets/images/ln-icon.svg" alt="share" />
              </Dropdown.Item>
              <Dropdown.Item
                href="https://twitter.com/intent/tweet?text=A completely free, comprehensive crypto wallet UI kit for developers / UI designers who want to develop standardized interactions and patterns%23whollet"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src="/assets/images/twitter-icon.svg" alt="share" />
              </Dropdown.Item>
              <Dropdown.Item
                href="https://www.facebook.com/sharer/sharer.php?u=https://whollet.io/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src="/assets/images/facebook-icon.svg" alt="share" />
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          <div className="nav-footer">
            <Follow followIcons={followIcons} />
            <Copy />
          </div>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  )
}

export default Navigation
