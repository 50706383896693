import React, { Component } from "react";
import "./DownloadButton.scss";

class DownloadButton extends Component {
  render() {
    return (
      <div className="hero_button--wrapper">
        <h3>DOWNLOAD FOR</h3>
        <div className="hero-down-icons">
          <a
            className="scetch-logo"
            href="/assets/download/Whollet-Sketch-v1-BETA.zip"
            download
          >
            <img
              className="sketch-logo-img"
              src={this.props.downloadIcons.sketch}
              onMouseOver={e =>
                (e.currentTarget.src = this.props.downloadIcons.sketchHover)
              }
              onMouseOut={e =>
                (e.currentTarget.src = this.props.downloadIcons.sketch)
              }
              alt=""
            />
          </a>
          <a
            className="figma-logo"
            href="/assets/download/Whollet-Figma-v1-BETA.zip"
            download
          >
            <img
              className="figma-logo-img"
              src={this.props.downloadIcons.figma}
              onMouseOver={e =>
                (e.currentTarget.src = this.props.downloadIcons.figmaHover)
              }
              onMouseOut={e =>
                (e.currentTarget.src = this.props.downloadIcons.figma)
              }
              alt=""
            />
          </a>
        </div>
      </div>
    );
  }
}

export default DownloadButton;
