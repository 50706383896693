import React from "react";
import "./Copy.scss";

function Copy() {
  return (
    <div className="footer-right">
      <p>
        Made with love️ for blockchain community by humans from &nbsp;
        <a
          href="https://mvpworkshop.co/"
          target="_blank"
          rel="noopener noreferrer"
        >
          MVP Workshop
        </a>
      </p>
    </div>
  );
}
export default Copy;
