import React from "react";
import "./Footer.scss";
import Follow from "../../components/Follow/Follow";
import Copy from "../../components/Copy/Copy";
const followIcons = {
  behance: "/assets/images/logo1.svg",
  behanceHover: "/assets/images/logo1-hover.svg",
  dribbble: "/assets/images/logo2.svg",
  dribbbleHover: "/assets/images/logo2-hover.svg"
};
function Footer() {
  return (
    <footer id="getintouch" className="footer-wrapper">
      <div className="footer-inner d-flex justify-content-between align-items-center">
        <Follow followIcons={followIcons} />
        <Copy />
      </div>
    </footer>
  );
}
export default Footer;
