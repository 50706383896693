import React from "react";
import "animate.css/animate.css";
import "./Heroanimation.scss";

function Heroanimation() {
  return (
    <div className="hero_img-animation--wrapper">
      <div className="mt-c1 first-margin">
        <img
          className="animated slideInUp delay-1"
          src="/assets/images/imgh1.png"
          srcSet="/assets/images/imgh1.png 1x, /assets/images/imgh1@2x.png 2x"
          alt=""
        />
        <img
          className="animated slideInUp delay-4"
          src="/assets/images/imgh2.png"
          srcSet="/assets/images/imgh2.png 1x, /assets/images/imgh2@2x.png 2x"
          alt=""
        />
      </div>
      <div className="align-self-start mt-c2 second-margin">
        <img
          className="animated slideInUp delay-2"
          src="/assets/images/imgh3.png"
          srcSet="/assets/images/imgh3.png 1x, /assets/images/imgh3@2x.png 2x"
          alt=""
        />
        <img
          className="animated slideInUp delay-5"
          src="/assets/images/imgh4.png"
          srcSet="/assets/images/imgh4.png 1x, /assets/images/imgh4@2x.png 2x"
          alt=""
        />
        <img
          className="animated slideInUp delay-9"
          src="/assets/images/imgh5.png"
          srcSet="/assets/images/imgh5.png 1x, /assets/images/imgh5@2x.png 2x"
          alt=""
        />
      </div>
      <div className="align-self-start">
        <img
          className="animated slideInUp delay-3"
          src="/assets/images/imgh6.png"
          srcSet="/assets/images/imgh6.png 1x, /assets/images/imgh6@2x.png 2x"
          alt=""
        />
        <img
          className="animated slideInUp delay-6"
          src="/assets/images/imgh7.png"
          srcSet="/assets/images/imgh7.png 1x, /assets/images/imgh7@2x.png 2x"
          alt=""
        />
        <img
          className="animated slideInUp delay-7"
          src="/assets/images/imgh8.png"
          srcSet="/assets/images/imgh8.png 1x, /assets/images/imgh8@2x.png 2x"
          alt=""
        />
        <img
          className="animated slideInUp delay-8"
          src="/assets/images/imgh9.png"
          srcSet="/assets/images/imgh9.png 1x, /assets/images/imgh9@2x.png 2x"
          alt=""
        />
      </div>
    </div>
  );
}

export default Heroanimation;
