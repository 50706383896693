import React from "react";
import "./CarouselStatic.scss";
function CarouselStatic() {
  return (
    <div className="carousel--left-static">
      <div>
        <h3>Fully editable Styleguide</h3>
        <p>
          To help you achieve full control over your project, the team of
          designers from the MVP Workshop has created a fully editable and
          well-organized UI styleguide. Check it out, and let us know how we can
          improve it even further.
        </p>
      </div>
    </div>
  );
}

export default CarouselStatic;
