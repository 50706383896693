import React from "react";
import "./TeamCards.scss";
//import Button from "react-bootstrap/Button";
import ModalButton from "../ModalButton/ModalButton";
function TeamCards() {
  return (
    <div
      id="sharelove"
      className="team-section-up d-flex justify-content-between align-items-center"
    >
      <div className="team-section-up_left w-50">
        <h2>The Whollet team</h2>
        <p className="team-subtitle">
          The Whollet UI Kit has been brought to you by humans from{" "}
          <a
            href="https://mvpworkshop.co/"
            target="_blank"
            rel="noopener noreferrer"
          >
            MVP Workshop
          </a>
          &nbsp;– a blockchain product R&D studio.
        </p>
        <p>
          We live and breathe decentralization as consultants, designers,
          developers, engineers and marketers - dedicated towards defining,
          building and growing your project as if it is our own.
        </p>
        <p>
          We also wanted to give back to the community, and to all who are
          driving blockchain towards mass adoption - possibly changing the world
          at least a little bit. Ping us on Twitter with feedback, questions, or
          if you just want to share some love. Definitely share this kit though
          :)
        </p>
        {/* <div className="team-mob-flex team-mob--img d-flex justify-content-center align-items-center">
          <img src="/assets/images/team-mob.svg" alt="" />
        </div>
        <p className="team-mob">
          If you’re here, that means you’re probably pretty awesome. As we love
          to talk about and explore new projects, you can claim your free
          product design consultation - it’s on us.
        </p> */}

        <div className="team-section-up--icons d-flex flex-wrap justify-content-between align-items-center">
          <div className="team-section-up--icon d-flex justify-content-start align-items-center">
            <img src="/assets/images/branding.svg" alt="" />
            <p>
              Branding and <br />
              Visual Identity
            </p>
          </div>
          <div className="team-section-up--icon d-flex justify-content-start align-items-center">
            <img src="/assets/images/design.svg" alt="" />
            <p>UX Design</p>
          </div>
          <div className="team-section-up--icon d-flex justify-content-start align-items-center">
            <img src="/assets/images/user.svg" alt="" />
            <p>
              User
              <br /> Research
            </p>
          </div>
          <div className="team-section-up--icon d-flex justify-content-start align-items-center">
            <img src="/assets/images/ui.svg" alt="" />
            <p>UI Design</p>
          </div>
          <div className="team-section-up--icon d-flex justify-content-start align-items-center">
            <img src="/assets/images/prototypes.svg" alt="" />
            <p>
              Interactive <br />
              Prototypes
            </p>
          </div>
          <div className="team-section-up--icon d-flex justify-content-start align-items-center">
            <img src="/assets/images/usability.svg" alt="" />
            <p>
              Usability <br />
              Testing
            </p>
          </div>
        </div>
        {/* <Button className="team-mob-flex mob-button" variant="primary">
          Claim consultation
          <img src="/assets/images/message-circle.svg" alt="" />
        </Button> */}
      </div>
      <div className="team-section-up_right w-50 align-self-stretch">
        <div className="team-section-up_right-inner">
          <div className="d-flex justify-content-center align-items-center">
            <img
              className="team-mvp-logo"
              src="/assets/images/mvp-logo.svg"
              alt=""
            />
          </div>
          <div className="team-mob--image">
            <img className="" src="/assets/images/team-mob.svg" alt="" />
          </div>

          <p className="text-center">
            If you’re here, that means you’re probably pretty awesome. As we
            love to talk about and explore new projects, you can claim your free
            product design consultation - it’s on us.
          </p>
          <div>
            {/* <Button className="button-desc" variant="primary">
              Claim consultation
              <img src="/assets/images/message-circle.svg" alt="" />
            </Button> */}
            <ModalButton />
          </div>
        </div>
      </div>
    </div>
  );
}

export default TeamCards;
