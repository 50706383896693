import React from "react";
import "./Subheader.scss";

class Subheader extends React.Component {
  render() {
    return <h2>{this.props.subheader}</h2>;
  }
}

export default Subheader;
